<section id="fotos" class="seccion-fotos">
    <div class="container-fluid">

        <div class="row row-cols-md-2 row-cols-lg-4">

            <div class="fotos-item animalo" data-animate-effect="fadeIn">
            <img src="/assets/img/fotos/foto1.jpg" alt="">
            </div>
            <div class="fotos-item animalo" data-animate-effect="fadeIn">
            <img src="/assets/img/fotos/foto2.jpg" alt="">
            </div>
            <div class="fotos-item animalo" data-animate-effect="fadeIn">
            <img src="/assets/img/fotos/foto3.jpg" alt="">
            </div>
            <div class="fotos-item animalo" data-animate-effect="fadeIn">
            <img src="/assets/img/fotos/foto4.jpg" alt="">
            </div>

        </div>

    </div>
</section>