<script>
    import Common from './Common.svelte';
    import Instituciones from '../components/Instituciones.svelte';
    import Nosotros from '../components/Nosotros.svelte';
    import Fotos from '../components/Fotos.svelte';
    import Footer from '../components/Footer.svelte';

    export let esp;
    let oculto=false, pagina=2, url=[ "institucion", "institution" ], title="Institución";
</script>

<main>
    <Common {esp} clase=".link-conocenos" {url} {title} />
    <Nosotros {esp} {oculto} />
    <Instituciones />
    <Fotos />
</main>
<Footer {esp} {pagina} />