<script>
    import Common from './Common.svelte';
    import Feed from '../components/InstaFeed.svelte';
    import Video from '../components/Video.svelte';
    import Fotos from '../components/Fotos.svelte';
    import Footer from '../components/Footer.svelte';

    export let esp;
    let pagina = 4, url = [ "vida-escolar", "school-life" ], title="Vida Escolar";
</script>

<main>
    <Common {esp} clase = ".link-conocenos" {url} {title} />
    <section id="vida-escolar" class="container-fluid my-section">

        <div class="container text-center">
            <h2 Class="h2 titulo-seccion">{ esp ? "Vida Escolar" : "School Life"}</h2>
            <p Class="parrafo-seccion"> { esp ?
                                            "Feed de nuestro Instagram."
                                            :
                                            "Our Instagram Feed."
                                        }
            </p>
        </div>
    
        <Feed {esp} />
        <Video {esp} />
        <Fotos />
    
    </section>
</main>
<Footer {esp} {pagina} />