<script>
    import { Router, Route } from 'svelte-routing';
    import NavBottom from '../components/NavBottom.svelte';
    import Personal from '../components/Personal.svelte';
    import NoFound from './404.svelte';
    import Instituciones from '../components/Instituciones.svelte';
    import Footer from '../components/Footer.svelte';

    export let esp;
    // console.log(Object.values(id))
    // let valor = Object.values(id);
    // let uri = valor[0]
    let pagina = 3;

</script>

<main>
    <Router>
        {#if esp}
            <Route path="/administracion">
                <Personal {esp} eq="administracion" url={"/our-team/administration"} title={"Personal-Administración"} />
            </Route>
            <Route path="/parvularia">
                <Personal {esp} eq="parvularia" url={"/our-team/preschool"} title={"Personal-Parvularia"} />
            </Route>
            <Route path="/primaria">
                <Personal {esp} eq="primaria" url={"/our-team/primary"} title={"Personal-Primaria"} />
            </Route>
            <Route path="/secundaria">
                <Personal {esp} eq="secundaria" url={"/our-team/highschool"} title={"Personal-Secundaria"} />
            </Route>
            <Route path="/nivelacion">
                <Personal {esp} eq="nivelacion" url={"/our-team/development"} title={"Personal-Nivelación"} />
            </Route>
            <Route path="/">
                <Personal {esp} eq="parvularia" url={"/our-team/preschool"} title={"Personal-Parvularia"} />
            </Route>
        {:else}
            <Route path="/administration">
                <Personal {esp} eq="administration" url={"/personal/administracion"} title={"Personal-Administración"} />
            </Route>
            <Route path="/preschool">
                <Personal {esp} eq="preschool" url={"/personal/parvularia"} title={"Personal-Parvularia"} />
            </Route>
            <Route path="/primary">
                <Personal {esp} eq="primary" url={"/personal/primaria"} title={"Personal-Primaria"} />
            </Route>
            <Route path="/highschool">
                <Personal {esp} eq="highschool" url={"/personal/secundaria"} title={"Personal-Secundaria"} />
            </Route>
            <Route path="/development">
                <Personal {esp} eq="development" url={"/personal/nivelacion"} title={"Personal-Nivelación"} />
            </Route>
            <Route path="/">
                <Personal {esp} eq="preschool" url={"/personal/parvularia"} title={"Personal-Parvularia"} />
            </Route>
        {/if}

        <Route path="*">
            <NoFound start="true" />
        </Route>
        <NavBottom {esp} />
    </Router>

    <Instituciones />
</main>
<Footer {esp} {pagina} />