<script>
    import { onMount } from 'svelte';
    import Fecha from './Fecha.svelte';
    export let esp;

    onMount( () => {
        sLightbox()
    } )

    const sLightbox = () => {

        new SimpleLightbox('.gallery a', {
            overlayOpacity: 0.9,
            captionPosition: 'outside',
        })

        // jQuery('.venobox').venobox({
        //     spinner    : 'cube-grid',
        //     spinColor    : '#fbb802',
        //     border: '5px',
        //     bgcolor: '#fbb802',
        //     arrowsColor: '#fbb802',
        //     closeColor : "#fbb802",
        //     infinigall: true,
        //     numeratio: true,
        //     numerationColor: '#fbb802',
        //     titleattr: 'title',
        //     titleColor: '#fbb802',
        // });

        // new VenoBox({
        //     selector: '.venobox',
        //     spinner    : 'grid',
        //     spinColor    : '#fbb802',
        //     border: '5px',
        //     bgcolor: '#fbb802',
        //     infinigall: true,
        //     numeration: true,
        //     titleattr: 'title',
        //     navTouch: false,
        //     toolsColor: '#fbb802',
        // });
    }
</script>
<section id="fechas" class="container-fluid">
    <div class="container my-5 pb-3 sombra card">

        <h2 Class="h2 text-center titulo-seccion">{ esp ? "Fechas Importantes" : "Important Dates" }</h2>
        <h4 class="text-center sub-titulo-seccion">{ esp ? "Información Importante" : "Important Information" }</h4>

        <div id="info" class="gallery row row-cols-2 row-cols-sm-3 row-cols-md-4 d-flex justify-content-center">
            <Fecha url="/assets/img/eventos/fechas.webp" title="Fechas importantes 2024-2025"></Fecha>
            <Fecha url="/assets/img/eventos/sep_01.webp" title="Día de la salud bucal"></Fecha>
            <Fecha url="/assets/img/eventos/sep_02.webp" title="Primera ronda cranium vs cranium"></Fecha>
            <Fecha url="/assets/img/eventos/sep_03.webp" title="Vacación día de la independencia"></Fecha>
            <Fecha url="/assets/img/eventos/sep_04.webp" title="Segunda ronda cranium vs cranium"></Fecha>
            <Fecha url="/assets/img/eventos/sep_05.webp" title="Toma fotografía carnet estudiantil"></Fecha>
            <Fecha url="/assets/img/eventos/sep_06.webp" title="Cranium vs cranium final"></Fecha>
            <Fecha url="/assets/img/eventos/sep_07.webp" title="Elección gobierno estudiantil"></Fecha>
            <Fecha url="/assets/img/eventos/sep_08.webp" title="Semana de lectura (1 - 5 grado)"></Fecha>
            <Fecha url="/assets/img/eventos/sep_09.webp" title="Capacitación maestros"></Fecha>
            <Fecha url="/assets/img/eventos/sep_10.webp" title="Semana de colores"></Fecha>
            <Fecha url="/assets/img/eventos/sep_11.webp" title="Semana rally de robótica"></Fecha>
            <Fecha url="/assets/img/eventos/sep_12.webp" title="Día del niño"></Fecha>
            <Fecha url="/assets/img/eventos/sep_13.webp" title="Torneo de deporte"></Fecha>
        </div>

    </div>
</section>