<script>
    import { conectionOnLine } from '../stores/stores';
    import MessageOffLine from './MessageOffLine.svelte';
    export let esp;
</script>

<div class="container instagram-feed">
    {#if $conectionOnLine}
         <!-- content here -->
         <script src="https://apps.elfsight.com/p/platform.js" defer></script>
         <div class="wall"></div>
         <div class="elfsight-app-fb5ebeca-3a74-4647-b6da-2c4b478c6250"></div>
    {:else}
        <MessageOffLine {esp} />
    {/if}
</div>