<script>
    import Head from './Head.svelte';
    import Navbar from './Navbar.svelte';
</script>

<Head />
<nav class="navbar navbar-expand-lg navbar-light bg-blanco shadow" id="navbar-fixed">
    <Navbar />
</nav>
<nav class="navbar navbar-expand-lg navbar-light bg-blanco shadow" id="navbar-top">
    <Navbar />
</nav>