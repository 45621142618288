<header id="inicio" class="container-fluid barra-top-info bg-azul">
    <div class="container-fluid barra-top-info-items py-2 px-2">
        <div class="row no-gutters">

            <div class="col-6 d-flex text-white align-items-center">
                <div class="icono me-2 bg-naranja">
                    <i class="fas fa-map-marker-alt"></i>
                </div>
                <div>
                    <span>89 Av. Nte. y 3a Calle Pte. #4628 Colonia Escalón, San Salvador.</span>
                </div>
            </div>

            <div class="col-6">
                <div class="row no-gutters">

                    <div class="col-7 d-flex text-white align-items-center">
                    <div class="icono me-2 bg-naranja">
                        <i class="far fa-envelope"></i>
                    </div>
                    <div>
                        <span>info1@abcbilingualschool.edu.sv</span>
                    </div>
                    </div>

                    <div class="col-5 d-flex text-white justify-content-end align-items-center">
                    <div class="icono me-2 bg-naranja">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                    <div>
                        <span>2264-0508/7696-9556</span>
                    </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</header>