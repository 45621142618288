<script>
    export let esp;
</script>

<h2 Class="h3 text-center">{
                    esp ?
                    'Esta sección no se puede visualizar por falta de internet'
                    :
                    'This section cannot be displayed due to lack of internet'
}</h2>       
